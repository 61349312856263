import {
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationReturnType,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  computed,
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  ApiAuctionsListingParams,
  ApiReconcileAuction201,
  AuctionBrandsDto,
  AuctionItemEntity,
  AuctionModelDto,
  AuctionVersionDto,
  AuctionsListingDto,
  BackendErrorEntity,
  CreateAuctionBodyDto,
  ReconcileAuctionBodyDto,
  UpdateAuctionBodyDto
} from '.././types'
import { fetchApiInstance } from '../../fetchApiInstance';
import type { ErrorType, BodyType } from '../../fetchApiInstance';

type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const apiAuctionsListingVersions = (
    modelId: MaybeRef<string | undefined | null>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      modelId = unref(modelId);
      
      return fetchApiInstance<AuctionVersionDto>(
      {url: `/auctions/model/${modelId}`, method: 'GET'
    },
      options);
    }
  

export const getApiAuctionsListingVersionsQueryKey = (modelId: MaybeRef<string | undefined | null>,) => {
    return ['auctions','model',modelId] as const;
    }

    
export const getApiAuctionsListingVersionsQueryOptions = <TData = Awaited<ReturnType<typeof apiAuctionsListingVersions>>, TError = ErrorType<BackendErrorEntity>>(modelId: MaybeRef<string | undefined | null>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiAuctionsListingVersions>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getApiAuctionsListingVersionsQueryKey(modelId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof apiAuctionsListingVersions>>> = () => apiAuctionsListingVersions(modelId, requestOptions);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(modelId))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof apiAuctionsListingVersions>>, TError, TData> 
}

export type ApiAuctionsListingVersionsQueryResult = NonNullable<Awaited<ReturnType<typeof apiAuctionsListingVersions>>>
export type ApiAuctionsListingVersionsQueryError = ErrorType<BackendErrorEntity>

export const useApiAuctionsListingVersions = <TData = Awaited<ReturnType<typeof apiAuctionsListingVersions>>, TError = ErrorType<BackendErrorEntity>>(
 modelId: MaybeRef<string | undefined | null>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiAuctionsListingVersions>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getApiAuctionsListingVersionsQueryOptions(modelId,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const apiAuctionsListingModels = (
    brandId: MaybeRef<string | undefined | null>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      brandId = unref(brandId);
      
      return fetchApiInstance<AuctionModelDto>(
      {url: `/auctions/brand/${brandId}`, method: 'GET'
    },
      options);
    }
  

export const getApiAuctionsListingModelsQueryKey = (brandId: MaybeRef<string | undefined | null>,) => {
    return ['auctions','brand',brandId] as const;
    }

    
export const getApiAuctionsListingModelsQueryOptions = <TData = Awaited<ReturnType<typeof apiAuctionsListingModels>>, TError = ErrorType<BackendErrorEntity>>(brandId: MaybeRef<string | undefined | null>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiAuctionsListingModels>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getApiAuctionsListingModelsQueryKey(brandId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof apiAuctionsListingModels>>> = () => apiAuctionsListingModels(brandId, requestOptions);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(brandId))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof apiAuctionsListingModels>>, TError, TData> 
}

export type ApiAuctionsListingModelsQueryResult = NonNullable<Awaited<ReturnType<typeof apiAuctionsListingModels>>>
export type ApiAuctionsListingModelsQueryError = ErrorType<BackendErrorEntity>

export const useApiAuctionsListingModels = <TData = Awaited<ReturnType<typeof apiAuctionsListingModels>>, TError = ErrorType<BackendErrorEntity>>(
 brandId: MaybeRef<string | undefined | null>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiAuctionsListingModels>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getApiAuctionsListingModelsQueryOptions(brandId,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const apiAuctionsListingBrands = (
    
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      
      
      return fetchApiInstance<AuctionBrandsDto>(
      {url: `/auctions/brands`, method: 'GET'
    },
      options);
    }
  

export const getApiAuctionsListingBrandsQueryKey = () => {
    return ['auctions','brands'] as const;
    }

    
export const getApiAuctionsListingBrandsQueryOptions = <TData = Awaited<ReturnType<typeof apiAuctionsListingBrands>>, TError = ErrorType<BackendErrorEntity>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiAuctionsListingBrands>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getApiAuctionsListingBrandsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof apiAuctionsListingBrands>>> = () => apiAuctionsListingBrands(requestOptions);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof apiAuctionsListingBrands>>, TError, TData> 
}

export type ApiAuctionsListingBrandsQueryResult = NonNullable<Awaited<ReturnType<typeof apiAuctionsListingBrands>>>
export type ApiAuctionsListingBrandsQueryError = ErrorType<BackendErrorEntity>

export const useApiAuctionsListingBrands = <TData = Awaited<ReturnType<typeof apiAuctionsListingBrands>>, TError = ErrorType<BackendErrorEntity>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiAuctionsListingBrands>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getApiAuctionsListingBrandsQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const apiAuctionsListing = (
    params?: MaybeRef<ApiAuctionsListingParams>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      params = unref(params);
      
      return fetchApiInstance<AuctionsListingDto>(
      {url: `/auctions`, method: 'GET',
        params: unref(params)
    },
      options);
    }
  

export const getApiAuctionsListingQueryKey = (params?: MaybeRef<ApiAuctionsListingParams>,) => {
    return ['auctions', ...(params ? [params]: [])] as const;
    }

    
export const getApiAuctionsListingQueryOptions = <TData = Awaited<ReturnType<typeof apiAuctionsListing>>, TError = ErrorType<BackendErrorEntity>>(params?: MaybeRef<ApiAuctionsListingParams>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiAuctionsListing>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getApiAuctionsListingQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof apiAuctionsListing>>> = () => apiAuctionsListing(params, requestOptions);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof apiAuctionsListing>>, TError, TData> 
}

export type ApiAuctionsListingQueryResult = NonNullable<Awaited<ReturnType<typeof apiAuctionsListing>>>
export type ApiAuctionsListingQueryError = ErrorType<BackendErrorEntity>

export const useApiAuctionsListing = <TData = Awaited<ReturnType<typeof apiAuctionsListing>>, TError = ErrorType<BackendErrorEntity>>(
 params?: MaybeRef<ApiAuctionsListingParams>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiAuctionsListing>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getApiAuctionsListingQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const apiCreateAuction = (
    createAuctionBodyDto: MaybeRef<CreateAuctionBodyDto>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      createAuctionBodyDto = unref(createAuctionBodyDto);
      
      return fetchApiInstance<AuctionItemEntity>(
      {url: `/auctions`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createAuctionBodyDto
    },
      options);
    }
  


export const getApiCreateAuctionMutationOptions = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiCreateAuction>>, TError,{data: BodyType<CreateAuctionBodyDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof apiCreateAuction>>, TError,{data: BodyType<CreateAuctionBodyDto>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiCreateAuction>>, {data: BodyType<CreateAuctionBodyDto>}> = (props) => {
          const {data} = props ?? {};

          return  apiCreateAuction(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ApiCreateAuctionMutationResult = NonNullable<Awaited<ReturnType<typeof apiCreateAuction>>>
    export type ApiCreateAuctionMutationBody = BodyType<CreateAuctionBodyDto>
    export type ApiCreateAuctionMutationError = ErrorType<BackendErrorEntity>

    export const useApiCreateAuction = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiCreateAuction>>, TError,{data: BodyType<CreateAuctionBodyDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof apiCreateAuction>>,
        TError,
        {data: BodyType<CreateAuctionBodyDto>},
        TContext
      > => {

      const mutationOptions = getApiCreateAuctionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const apiAuctionDetail = (
    auctionId: MaybeRef<string | undefined | null>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      auctionId = unref(auctionId);
      
      return fetchApiInstance<AuctionItemEntity>(
      {url: `/auctions/${auctionId}`, method: 'GET'
    },
      options);
    }
  

export const getApiAuctionDetailQueryKey = (auctionId: MaybeRef<string | undefined | null>,) => {
    return ['auctions',auctionId] as const;
    }

    
export const getApiAuctionDetailQueryOptions = <TData = Awaited<ReturnType<typeof apiAuctionDetail>>, TError = ErrorType<BackendErrorEntity>>(auctionId: MaybeRef<string | undefined | null>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiAuctionDetail>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getApiAuctionDetailQueryKey(auctionId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof apiAuctionDetail>>> = () => apiAuctionDetail(auctionId, requestOptions);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(auctionId))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof apiAuctionDetail>>, TError, TData> 
}

export type ApiAuctionDetailQueryResult = NonNullable<Awaited<ReturnType<typeof apiAuctionDetail>>>
export type ApiAuctionDetailQueryError = ErrorType<BackendErrorEntity>

export const useApiAuctionDetail = <TData = Awaited<ReturnType<typeof apiAuctionDetail>>, TError = ErrorType<BackendErrorEntity>>(
 auctionId: MaybeRef<string | undefined | null>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiAuctionDetail>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getApiAuctionDetailQueryOptions(auctionId,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const apiUpdateAuction = (
    auctionId: MaybeRef<string | undefined | null>,
    updateAuctionBodyDto: MaybeRef<UpdateAuctionBodyDto>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      auctionId = unref(auctionId);
updateAuctionBodyDto = unref(updateAuctionBodyDto);
      
      return fetchApiInstance<AuctionItemEntity>(
      {url: `/auctions/${auctionId}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: updateAuctionBodyDto
    },
      options);
    }
  


export const getApiUpdateAuctionMutationOptions = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiUpdateAuction>>, TError,{auctionId: string;data: BodyType<UpdateAuctionBodyDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof apiUpdateAuction>>, TError,{auctionId: string;data: BodyType<UpdateAuctionBodyDto>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiUpdateAuction>>, {auctionId: string;data: BodyType<UpdateAuctionBodyDto>}> = (props) => {
          const {auctionId,data} = props ?? {};

          return  apiUpdateAuction(auctionId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ApiUpdateAuctionMutationResult = NonNullable<Awaited<ReturnType<typeof apiUpdateAuction>>>
    export type ApiUpdateAuctionMutationBody = BodyType<UpdateAuctionBodyDto>
    export type ApiUpdateAuctionMutationError = ErrorType<BackendErrorEntity>

    export const useApiUpdateAuction = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiUpdateAuction>>, TError,{auctionId: string;data: BodyType<UpdateAuctionBodyDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof apiUpdateAuction>>,
        TError,
        {auctionId: string;data: BodyType<UpdateAuctionBodyDto>},
        TContext
      > => {

      const mutationOptions = getApiUpdateAuctionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const apiSetClearingPriceInClosedAuction = (
    auctionId: MaybeRef<string | undefined | null>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      auctionId = unref(auctionId);
      
      return fetchApiInstance<void>(
      {url: `/auctions/${auctionId}/setClearingPrice`, method: 'PATCH'
    },
      options);
    }
  


export const getApiSetClearingPriceInClosedAuctionMutationOptions = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiSetClearingPriceInClosedAuction>>, TError,{auctionId: string}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof apiSetClearingPriceInClosedAuction>>, TError,{auctionId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiSetClearingPriceInClosedAuction>>, {auctionId: string}> = (props) => {
          const {auctionId} = props ?? {};

          return  apiSetClearingPriceInClosedAuction(auctionId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ApiSetClearingPriceInClosedAuctionMutationResult = NonNullable<Awaited<ReturnType<typeof apiSetClearingPriceInClosedAuction>>>
    
    export type ApiSetClearingPriceInClosedAuctionMutationError = ErrorType<BackendErrorEntity>

    export const useApiSetClearingPriceInClosedAuction = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiSetClearingPriceInClosedAuction>>, TError,{auctionId: string}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof apiSetClearingPriceInClosedAuction>>,
        TError,
        {auctionId: string},
        TContext
      > => {

      const mutationOptions = getApiSetClearingPriceInClosedAuctionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const apiReconcileAuction = (
    auctionId: MaybeRef<string | undefined | null>,
    reconcileAuctionBodyDto: MaybeRef<ReconcileAuctionBodyDto>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      auctionId = unref(auctionId);
reconcileAuctionBodyDto = unref(reconcileAuctionBodyDto);
      
      return fetchApiInstance<ApiReconcileAuction201>(
      {url: `/auctions/${auctionId}/reconcile`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: reconcileAuctionBodyDto
    },
      options);
    }
  


export const getApiReconcileAuctionMutationOptions = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiReconcileAuction>>, TError,{auctionId: string;data: BodyType<ReconcileAuctionBodyDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof apiReconcileAuction>>, TError,{auctionId: string;data: BodyType<ReconcileAuctionBodyDto>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiReconcileAuction>>, {auctionId: string;data: BodyType<ReconcileAuctionBodyDto>}> = (props) => {
          const {auctionId,data} = props ?? {};

          return  apiReconcileAuction(auctionId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ApiReconcileAuctionMutationResult = NonNullable<Awaited<ReturnType<typeof apiReconcileAuction>>>
    export type ApiReconcileAuctionMutationBody = BodyType<ReconcileAuctionBodyDto>
    export type ApiReconcileAuctionMutationError = ErrorType<BackendErrorEntity>

    export const useApiReconcileAuction = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiReconcileAuction>>, TError,{auctionId: string;data: BodyType<ReconcileAuctionBodyDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof apiReconcileAuction>>,
        TError,
        {auctionId: string;data: BodyType<ReconcileAuctionBodyDto>},
        TContext
      > => {

      const mutationOptions = getApiReconcileAuctionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    