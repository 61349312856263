<script setup lang="ts">
import type { PropType } from 'vue';

import type { AuctionAssetBusEntity, AuctionAssetBusListingItem } from '~/apiClient';

type Restriction = 'toBeScraped' | 'expiredInspection';

// PROPS
const props = defineProps({
    asset: { type: Object as PropType<AuctionAssetBusEntity | AuctionAssetBusListingItem>, required: true },
});

// COMPOSABLES
const { tt } = useTypedI18n();
const { logError } = useLogs();

// DATA
const type = computed<Restriction | undefined>(() => {
    if (props.asset.toBeScraped) return 'toBeScraped';

    if (isInspectionExpired()) return 'expiredInspection';
});
const bgClass = computed<string>(() => (type.value === 'toBeScraped' ? 'bg-red-600' : 'bg-yellow-600'));
const icon = computed<string>(() => (type.value === 'toBeScraped' ? 'i-mdi-recycle' : 'i-mdi-cog'));
const label = computed<TranslationKey>(() => (type.value === 'toBeScraped' ? 'asset.toBeScraped' : 'asset.expiredInspection'));

// METHODS
const isInspectionExpired = (): boolean => {
    try {
        return new Date(props.asset.inspectionExpiryDate) < new Date();
    } catch (e) {
        logError('Error while checking inspection date', { assetId: props.asset.id });
    }

    return false;
};
</script>

<template>
    <UBadge v-if="type" variant="solid" size="lg" class="flex gap-x-2 text-white" :class="bgClass">
        <UIcon class="h-5 w-5" :name="icon" />
        {{ tt(label) }}
    </UBadge>
</template>
